<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span v-if="!loading" class="headline"
          >{{ screen.name }} {{ $tc("device_status.title") }}</span
        >
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row v-if="loading">
            <v-col
              v-for="(item, index) of 2"
              :key="index"
              cols="12"
              sm="6"
              md="6"
            >
              <v-skeleton-loader
                type="image"
                height="120px"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="12" md="12">
              <div class="general">
                <span :class="device.is_online ? 'online' : 'offline'">
                  <v-icon :class="device.is_online ? 'online' : 'offline'">
                    fas fa-signal</v-icon
                  >
                  {{
                    device.is_online
                      ? $tc("device_status.online")
                      : $tc("device_status.offline")
                  }}
                </span>
                <span class="d-none d-md-contents" style="text-align: end"
                  >Última conexión : {{ getDate }}
                </span>
                <v-tooltip class="d-none d-md-contents" bottom>
                  <template v-slot:activator="{ on }">
                    <i
                      v-on="on"
                      @click="getDeviceStatus"
                      class="fas fa-sync-alt"
                    ></i>
                  </template>
                  <span>{{ $tc("device_status.update_device_info") }}</span>
                </v-tooltip>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <div class="ml-5" v-if="!device.is_online">
                {{ $tc("device_status.last_connection") }} <span class="font-weight-bold">{{ getDate}}</span>
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-row class="pl-5 pr-5">
                <v-col class="backColor" cols="12" sm="12" md="12">
                  <div class="item">
                    <v-icon style="text-align: start">
                      fas fa-temperature-high</v-icon
                    >
                    <span style="text-align: center">
                      {{ $tc("device_status.temperature") }}
                    </span>
                    <span style="text-align: end"
                      >~{{ device.cpu_temp }} °C</span
                    >
                  </div>
                  <v-progress-linear
                    :value="device.cpu_temp"
                    :color="device.cpu_temp < 80 ? 'green' : 'red'"
                    style="border-radius: 20px; max-width: 95%"
                    class="mt-5"
                    height="15"
                  >
                  </v-progress-linear>
                </v-col>
                <v-col class="backColor" cols="12" sm="12" md="12">
                  <div class="item">
                    <v-icon style="text-align: start"> fas fa-microchip</v-icon>
                    <span style="text-align: center"> CPU </span>
                    <span style="text-align: end"
                      >~{{ device.cpu_usage }} %</span
                    >
                  </div>
                  <v-progress-linear
                    :value="device.cpu_usage"
                    :color="device.cpu_usage < 80 ? 'green' : 'red'"
                    style="border-radius: 20px; max-width: 95%"
                    class="mt-5"
                    height="15"
                  >
                  </v-progress-linear>
                </v-col>
                <v-col class="backColor" cols="12" sm="12" md="12">
                  <div class="item">
                    <v-icon style="text-align: start"> fas fa-memory</v-icon>
                    <span style="text-align: center">
                      {{ $tc("device_status.memory") }}
                    </span>
                    <span style="text-align: end">{{ getMemory }} GB</span>
                  </div>
                  <v-progress-linear
                    :value="(device.memory_usage / device.memory_total) * 100"
                    :color="
                      (device.memory_usage / device.memory_total) * 100 < 50
                        ? 'green'
                        : 'red'
                    "
                    style="border-radius: 20px; max-width: 95%"
                    class="mt-5"
                    height="15"
                  >
                  </v-progress-linear>
                </v-col>
                <v-col
                  v-if="device.storage_usage"
                  class="backColor"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div class="item">
                    <v-icon style="text-align: start"> fas fa-hdd</v-icon>
                    <span style="text-align: center">
                      {{ $tc("device_status.storage") }}
                    </span>
                    <span style="text-align: end">{{ getStorage }} GB</span>
                  </div>
                  <v-progress-linear
                    :value="(device.storage_usage / device.storage_total) * 100"
                    :color="
                      (device.storage_usage / device.storage_total) * 100 < 80
                        ? 'green'
                        : 'red'
                    "
                    style="border-radius: 20px; max-width: 95%"
                    class="mt-5"
                    height="15"
                  >
                  </v-progress-linear>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              style="
                align-items: stretch;
                justify-content: center;
                display: flex;
              "
              cols="12"
              sm="6"
              md="6"
            >
              <v-row class="mt-5 pl-2 pr-2">
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    :loading="loading"
                    class="flex-grow-1 flex-shrink-0 white--text"
                    large
                    :disabled="!device.is_online"
                    rounded
                    @click="
                      ask({
                        name: ` ${$tc('device_status.buttons.restart')} ${
                          device.device_name
                        }`,
                        description: $tc(
                          'device_status.buttons.long_restart_message'
                        ),
                        action: 'restart',
                      })
                    "
                    :elevation="0"
                    :minHeight="60"
                    block
                    color="warning darken-1"
                  >
                    {{ $tc("device_status.buttons.restart") }} {{screen.is_online}}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    :loading="loading"
                    class="flex-grow-1 flex-shrink-0 white--text"
                    large
                    :disabled="!device.is_online"
                    rounded
                    :elevation="0"
                    :minHeight="60"
                    block
                    color="red darken-4"
                    @click="
                      ask({
                        name: ` ${$tc('device_status.buttons.shutdown')} ${
                          device.device_name
                        }`,
                        description: $tc(
                          'device_status.buttons.long_shutdown_message'
                        ),
                        action: 'shutdown',
                      })
                    "
                  >
                    {{ $tc("device_status.buttons.shutdown") }}
                  </v-btn>
                  <span class="pl-5">
                    {{ $tc("device_status.buttons.short_shutdown_message") }}
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    :loading="loading"
                    class="flex-grow-1 flex-shrink-0 white--text"
                    large
                    rounded
                    :disabled="!device.is_online"
                    :elevation="0"
                    :minHeight="60"
                    block
                    @click="
                      ask({
                        name: `${$tc('device_status.buttons.purge')} ${
                          device.device_name
                        }`,
                        description: $tc(
                          'device_status.buttons.long_purge_message'
                        ),
                        action: 'purge',
                      })
                    "
                    color="red darken-4"
                  >
                    {{ $tc("device_status.buttons.purge") }}
                  </v-btn>
                  <span class="pl-5 mt-5">
                    {{ $tc("device_status.buttons.short_purge_message") }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog max-width="600px" v-if="confirmDialog" v-model="confirmDialog">
      <confirm
        ref="confirmComponent"
        @success="actionConfirmed()"
        transition="dialog-bottom-transition"
        :message="action.description"
        :name="action.name"
        @cancel="confirmDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import confirm from "@/components/confirm";

export default {
  name: "device-status",
  props: ["screen"],
  components: {
    confirm,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      device: {},
      confirmDialog: false,
      action: {},
    };
  },
  computed: {
    getStorage() {
      return `${(this.device.storage_usage / 1024).toFixed(2)}/${(
        this.device.storage_total / 1024
      ).toFixed(2)}`;
    },
    getMemory() {
      return `${(this.device.memory_usage / 1024).toFixed(2)}/${(
        this.device.memory_total / 1024
      ).toFixed(2)}`;
    },
    getDate() {
      return moment(this.device.last_connectivity_event).format('DD MMMM YYYY, h:mm A');
    },
  },
  methods: {
    getDeviceStatus() {
      this.loading = true;
      var deviceStatus = fb.functions().httpsCallable("httpDeviceStatus");
      deviceStatus({ screenId: this.screen[".key"] })
        .then((result) => {
          this.device = result.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.loading = false;
          this.$emit("cancel");
        });
    },
    ask(item) {
      this.action = item;
      this.confirmDialog = true;
    },
    actionConfirmed() {
      var devicesActions = fb.functions().httpsCallable("httpDevicesActions");
      devicesActions({
        screenId: this.screen[".key"],
        action: this.action.action,
      })
        .then((result) => {
          this.$refs.confirmComponent.loading = false;
          this.snackbarText = this.action.action == "shutdown" ? this.$t('device_status.turn_off_message') : this.$t('device_status.restart_message'); 
          this.snackbar = true;
          this.confirmDialog = false;
        })
        .catch((err) => {
          this.$refs.confirmComponent.loading = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getDeviceStatus();
  },
};
</script>

<style scoped>
i {
  margin-right: 10px;
}

.item {
  display: grid;
  grid-template-columns: 20% 40% 40%;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.online {
  color: green;
  font-size: 18px;
  font-weight: bold;
}

.offline {
  color: rgb(116, 0, 0);
  font-size: 18px;
  font-weight: bold;
}

.backColor {
  background-color: #eaeaea;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 25px;
}

.general {
  display: grid;
  grid-template-columns: 50% 45% 5%;
}

@media only screen and (max-width: 600px) {
  .general {
    display: flex;
    margin-left: 10px;
  }

  .general i {
    display: none;
  }
}

.general i {
  text-align: end;
  font-size: 20px;
  cursor: pointer;
}
</style>